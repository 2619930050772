const INITIAL_STATE = {
    messagesMonth: null,
    messagesYear: null,
    messagesErros: null,
    messagesSpan: null,
    messagesCount: null,
    lastMessages: [],
    active: null
}

const formatReceived = action => {
    return {
        content_type: "",
        date_message: new Date().toISOString(),
        file: action.body.Data.file,
        id: action.body.Data.data.Info.Id,
        json_message: JSON.stringify(action.body.Data),
        message: action.body.Data.data.Text,
        message_direction: action.type == "MESSAGE.RECEIVED" ? 1 : 2,
        name: "",
        remote_jid: action.body.Data.data.Info.RemoteJid.split("@")[0]
    }
}

const formatSended = action => {
    return {
        content_type: "",
        date_message: new Date().toISOString(),
        file: "", //!action.body.Data.Type ? "" : action.body.Data.file,
        id: action.body.Data.Info.Id,
        json_message: JSON.stringify(action.body.Data),
        message: action.body.Data.Type ? action.body.Data.Type : action.body.Data.Text,
        message_direction: action.type == "MESSAGE.RECEIVED" ? 1 : 2,
        name: "",
        remote_jid: action.body.Data.Info.RemoteJid.split("@")[0]
    }
}

export const number = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "MESSAGES_NUMBER_ACTIVE_SET": {
            return { ...state, active: action.data }
        }
        // case "MESSAGE.SENDED":
        // case "MESSAGE.RECEIVED": {
        //     if (action.wid == state.active) {
        //         const data = [...state.lastMessages, (action.type == "MESSAGE.RECEIVED" ? formatReceived(action) : formatSended(action))]
        //         data.sort((a, b) => {
        //             if (new Date(a.date_message) > new Date(b.date_message))
        //                 return -1
        //             if (new Date(a.date_message) < new Date(b.date_message))
        //                 return 1
        //             return 0
        //         })
        //         data.pop()
        //         if (action.type == "MESSAGE.RECEIVED") {
        //             if (state.messagesCount.received) {
        //                 state.messagesCount.received.total++
        //                 state.messagesCount.received.totalWeek++
        //                 if (state.messagesCount.received.series.length == 0) {
        //                     state.messagesCount.received.series[state.messagesCount.received.series.length - 1]++
        //                 }
        //             }
        //         } else {
        //             if (state.messagesCount.sended) {
        //                 state.messagesCount.sended.total++
        //                 state.messagesCount.sended.totalWeek++
        //                 if (state.messagesCount.sended.series.length == 0) {
        //                     state.messagesCount.sended.series[state.messagesCount.sended.series.length - 1]++
        //                 }
        //             }
        //         }
        //         return { ...state, lastMessages: data }
        //     }
        // }
        case "MESSAGES_NUMBER_ACTIVE_MONTH": {
            return { ...state, messagesMonth: action.data }
        }
        case "MESSAGES_NUMBER_ACTIVE_YEAR": {
            return { ...state, messagesYear: action.data }
        }
        case "MESSAGES_NUMBER_ACTIVE_ERROS": {
            return { ...state, messagesErros: action.data }
        }
        case "MESSAGES_NUMBER_ACTIVE_SPAN": {
            return { ...state, messagesSpan: action.data }
        }
        case "MESSAGES_NUMBER_ACTIVE_COUNT": {
            return { ...state, messagesCount: action.data }
        }
        case "MESSAGES_NUMBER_ACTIVE_LAST": {
            return { ...state, lastMessages: action.data }
        }
        default: {
            return state
        }
    }
}