import axios from 'axios'
import url from '../../../config'
import { toast } from "react-toastify"
import { logout } from '../auth/loginActions'

export const getNumbers = () => {
    return dispatch => {
        axios.get(`${url.SEARCH_URL}/search/number`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    dispatch({ type: "UPDATE_NUMBERS", numbers: resp.data })
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    toast.error("Sessão expirada", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                } else {
                    toast.error("Erro ao consultar Numeros", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
                dispatch(logout())
            })
    }
}

export const getGeralChartMessages = () => {
    return dispatch => {
        return
        axios.get(`${url.SEARCH_URL}/search/dashboard/chart/messages`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    dispatch({ type: "GERAL_CHART_MESSAGES", chart: resp.data })
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar chart de mensagens enviada e recebidas", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export const setActiveNumber = (n) => {
    return dispatch => {
        dispatch({ type: "MESSAGES_NUMBER_ACTIVE_SET", data: n })
    }
}

export const getGeralChartMessagesMonth = (n) => {
    const baseURL = `${url.SEARCH_URL}/search/number${n ? "/" + n : ""}`
    return dispatch => {
        return
        axios.get(`${baseURL}/message-month`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    if (!n) {
                        dispatch({ type: "GERAL_CHART_MESSAGES_MONTH", chart: resp.data })
                    } else {
                        dispatch({ type: "MESSAGES_NUMBER_ACTIVE_MONTH", data: resp.data })
                    }
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar chart de mensagens do mês atual", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export const getGeralChartMessagesYear = (n) => {
    const baseURL = `${url.SEARCH_URL}/search/number${n ? "/" + n : ""}`
    return dispatch => {
        return
        axios.get(`${baseURL}/message-year`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    if (!n) {
                        dispatch({ type: "GERAL_CHART_MESSAGES_YEAR", chart: resp.data })
                    } else {
                        dispatch({ type: "MESSAGES_NUMBER_ACTIVE_YEAR", data: resp.data })
                    }
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar chart de mensagens do ano atual", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export const getGeralErros = (n) => {
    const baseURL = `${url.SEARCH_URL}/search/number${n ? "/" + n : ""}`
    return dispatch => {
        return
        axios.get(`${baseURL}/message-errors`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    if (!n) {
                        dispatch({ type: "GERAL_MESSAGES_ERROR", data: resp.data })
                    } else {
                        dispatch({ type: "MESSAGES_NUMBER_ACTIVE_ERROS", data: resp.data })
                    }
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar erros", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export const getGeralSpan = (n) => {
    const baseURL = `${url.SEARCH_URL}/search/number${n ? "/" + n : ""}`
    return dispatch => {
        return
        axios.get(`${baseURL}/message-span`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    if (!n) {
                        dispatch({ type: "GERAL_MESSAGES_SPAN", data: resp.data })
                    } else {
                        dispatch({ type: "MESSAGES_NUMBER_ACTIVE_SPAN", data: resp.data })

                    }
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar span", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export const getMessagesCount = (n) => {
    const baseURL = `${url.SEARCH_URL}/search/number${n ? "/" + n : ""}`
    return dispatch => {
        axios.get(`${baseURL}/message-count`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    dispatch({ type: "MESSAGES_NUMBER_ACTIVE_COUNT", data: resp.data })
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar span", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export const getMessagesNumber = (n) => {
    const baseURL = `${url.SEARCH_URL}/search/number${n ? "/" + n : ""}`
    return dispatch => {
        axios.get(`${baseURL}/messages`)
            .then(resp => {
                if (resp.status == 200 && resp.data != null) {
                    dispatch({ type: "MESSAGES_NUMBER_ACTIVE_LAST", data: resp.data })
                }
            })
            .catch(resp => {
                if (resp.response && resp.response.status == 401) {
                    dispatch(logout())
                } else {
                    toast.error("Erro ao carregar mensagens", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}

export function saveTitle(props) {
    return dispatch => {
        axios.post(`${url.ADMIN_URL}/admin/numbers/title`, props)
            .then(resp => {
                if (resp.data.success) {
                    dispatch({ type: "UPDATE_LABEL", data: props })
                    toast.success("Label savo com sucesso", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    })
                }
            })
    }
}