const INITIAL_STATE = {
    version: 0,
    start: false,
    success: false,
    session: null
}

export const vincule = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "MESSAGE.VINCULENUMBER.EXPIRE": {
            return { ...state, version: ++state.version }
        }
        case "MESSAGE.VINCULENUMBER.START": {
            return { ...state, start: true }
        }
        case "MESSAGE.VINCULENUMBER.SUCCESS": {
            return { ...state, success: true, session: action.body.Data }
        }
        case "MESSAGE.VINCULENUMBER.RESET": {
            return { ...state, start: false, success: false }
        }
        default: {
            return state
        }
    }
}