import axios from 'axios'
import url from '../../../config'
import { toast } from "react-toastify"
import {
  getNumbers,
  getGeralChartMessages,
  getGeralChartMessagesMonth,
  getGeralChartMessagesYear,
  getGeralErros,
  getGeralSpan
} from '../number/numberActions'

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const login = user => {
  return dispatch => {
    axios.post(`${url.ADMIN_URL}/admin/login`, user)
      .then(resp => {
        if (resp.data.success) {
          if (resp.data.result.access_token) {
            dispatch({ type: "LOGIN", user: resp.data.result })
            dispatch(getNumbers())
          } else {
            toast.error("Não foi possivel fazer login!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000
            })
          }
        } else {
          toast.error("Usuário ou senha incorreto!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
          })
        }
      })
  }
}

export const logout = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT" })
    dispatch({ type: "UPDATE_NUMBERS", numbers: [] })
    dispatch({ type: "GERAL_CHART_MESSAGES", chart: null })
  }
}