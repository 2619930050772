import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  // {
  //   id: "home",
  //   title: "Home",
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/",
  // },
  // {
  //   id: "vinculeNumber",
  //   title: "Novo Numero",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/vincule-number",
  // },
  // {
  //   id: "register",
  //   title: "Register",
  //   type: "item",
  //   icon: <Icon.Circle size={10} />,
  //   navLink: "/pages/register",
  //   permissions: ["admin", "editor"]
  // },
  // {
  //   id: "forgotPassword",
  //   title: "Forgot Password",
  //   type: "item",
  //   icon: <Icon.Circle size={10} />,
  //   navLink: "/pages/forgot-password",
  //   permissions: ["admin", "editor"]
  // },
  // {
  //   id: "chat",
  //   title: "Chat",
  //   type: "item",
  //   icon: <Icon.MessageSquare size={16} />,
  //   navLink: "/chat",
  //   permissions: ["admin", "editor"]
  // }
]

export default horizontalMenuConfig
