const INITIAL_STATE = {
    numbers: [],
    geralChartMessages: null,
    lastMessage: null,
    updateLabel: []
}

export const number = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "MESSAGE.NUMBER.LOGIN": {
            state.numbers.forEach(item => {
                if (item.phone_number == action.wid) {
                    item.active = true
                    item.online = true
                    item.login = action.body.Data.date
                }
            })
            return { ...state, numbers: [...state.numbers] }
        }
        case "MESSAGE.NUMBER.LOGOUT": {
            state.numbers.forEach(item => {
                if (item.phone_number == action.wid) {
                    item.active = true
                    item.online = false
                    item.logout = action.body.Data.date
                }
            })
            return { ...state, numbers: [...state.numbers] }
        }
        case "MESSAGE.NUMBER.DISCONECT": {
            state.numbers.forEach(item => {
                if (item.phone_number == action.wid) {
                    item.active = false
                    item.online = false
                }
            })
            return { ...state, numbers: [...state.numbers] }
        }
        case "UPDATE_LABEL": {
            return {
                ...state,
                updateLabel: [...state.updateLabel, action.data]
            }
        }
        case "UPDATE_NUMBERS": {
            return { ...state, numbers: [...action.numbers] }
        }
        case "GERAL_CHART_MESSAGES": {
            return { ...state, geralChartMessages: action.chart }
        }
        case "GERAL_CHART_MESSAGES_MONTH": {
            return { ...state, geralChartMessagesMonth: action.chart }
        }
        case "GERAL_CHART_MESSAGES_YEAR": {
            return { ...state, geralChartMessagesYear: action.chart }
        }
        case "GERAL_MESSAGES_ERROR": {
            return { ...state, geralErros: action.data }
        }
        case "GERAL_MESSAGES_SPAN": {
            return { ...state, geralSpan: action.data }
        }
        default: {
            return state
        }
    }
}