import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "saldo",
    type: "groupHeader",
    groupTitle: "WhatsApp"
  },
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    type: "groupHeader",
    groupTitle: "WhatsApp"
  },
  // {
  //   id: "vinculeNumber",
  //   title: "Vincular Web",
  //   type: "item",
  //   icon: <Icon.Droplet size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/vincule-number"
  // },
  {
    id: "vinculeNumberBeta",
    title: "Vincular Beta",
    type: "item",
    icon: <Icon.Droplet size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/vincule-number-beta"
  },
  {
    id: "numbers",
    title: "Numeros",
    type: "collapse",
    icon: <Icon.Phone size={20} />,
    badge: "warning",
    children: []
  },
  {
    id: "numberFilter",
    title: "Filtro Mensagem",
    type: "item",
    icon: <Icon.Search size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/number/filter"
  },
  {
    type: "groupHeader",
    groupTitle: "TEMPLATES"
  },
  {
    id: "templates",
    title: "Gerenciar",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/templates"
  },
  {
    type: "groupHeader",
    groupTitle: "CONTA"
  },
  {
    id: "historyPurchase",
    title: "Histórico",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/history"
  },
  // {
  //   id: "purchaseCredits",
  //   title: "Comprar Créditos",
  //   type: "item",
  //   icon: <Icon.ShoppingCart size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/credits-purchase"
  // },
  {
    type: "groupHeader",
    groupTitle: "DESENVOLVEDOR"
  },
  {
    id: "api",
    title: "API",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "sendMessage",
        title: "Documentação",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/api/doc"
      }
    ]
  },
  {
    id: "lib",
    title: "Bibliotecas",
    type: "collapse",
    icon: <Icon.Layers size={20} />,
    children: [
      {
        id: "sendMessage",
        title: "C#",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/lib/net"
      },
      {
        id: "findMessage",
        title: "Go",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin"],
        navLink: "/lib/go"
      }
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "Outros"
  },
  {
    id: "faq",
    title: "Ajuda",
    type: "item",
    icon: <Icon.HelpCircle size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/faq"
  },
  {
    id: "suporte",
    title: "Suporte",
    type: "item",
    icon: <Icon.HelpCircle size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/suporte"
  }
]

export default navigationConfig
