const initialState = {
  chats: [],
  contacts: [],
  chatContacts: [],
  pinned: [],
  status: "active",
  filteredContacts: [],
  filteredChats: [],
  activeChatData: null,
  activeChatID: null,
  activeUser: null
}

const formatNumber = number => {
  const jid = number.split("@")[0]
  const country = jid.slice(0, 4)
  const phone = jid.slice(jid.length - 8, jid.length)
  return country + phone
}

const chats = (state = initialState, action) => {
  // switch (action.type) {
  //   case "MESSAGE.SENDED":
  //   case "MESSAGE.RECEIVED": {
  //     const remoteJid = action.body.Data.data ?
  //       action.body.Data.data.Info.RemoteJid : action.body.Data.Info.RemoteJid
  //     const text = action.body.Data.data ?
  //       action.body.Data.data.Text ? action.body.Data.data.Text : action.body.Data.data.Type : action.body.Data.Text
  //     const sent = action.body.Data.data ? false : true

  //     const evtNumber = formatNumber(remoteJid)
  //     if (state.activeChatID) {
  //       const actNumber = formatNumber(state.activeChatID)
  //       if (evtNumber == actNumber) {
  //         const data = state.activeChatData
  //         data.msg.push({
  //           textContent: text,
  //           time: new Date().toGMTString(),
  //           isSent: sent,
  //           isSeen: true
  //         })
  //         return {
  //           ...state, activeChatData: data
  //         }
  //       }
  //     }

  //     const data = state.chatContacts
  //     data.forEach((x, index) => {
  //       const number = formatNumber(x.uid)
  //       if (number == evtNumber) {
  //         x.order = -1
  //         x.about = action.body.Data.data.Text
  //         if (state.activeChatID != number) {
  //           if (!x.pendingMsg) {
  //             x.pendingMsg = 1
  //           } else {
  //             x.pendingMsg++
  //           }
  //         }
  //       } else {
  //         x.order = index
  //       }
  //     })
  //     data.sort((a, b) => {
  //       if (a.order > b.order)
  //         return 1
  //       if (a.order < b.order)
  //         return -1
  //       return 0
  //     })
  //     return { ...state, chatContacts: data }
  //   }
  //   case "ACTIVE_CHAT": {
  //     const data = state.chatContacts
  //     data.forEach((x, index) => {
  //       const number = formatNumber(x.uid)
  //       if (number == action.payload.activeChatID) {
  //         x.pendingMsg = 0
  //       }
  //     })
  //     return {
  //       ...state,
  //       activeChatID: action.payload.activeChatID,
  //       activeUser: action.payload.activeUser,
  //       activeChatData: action.payload.activeChat,
  //       chatContacts: data
  //     }
  //   }
  //   case "GET_CONTACTS":
  //     return { ...state, chats: action.chats, contacts: action.contacts }
  //   case "GET_CHAT_CONTACTS":
  //     return { ...state, chatContacts: action.chats }
  //   case "SEND_MESSAGE":
  //     let sendMsg,
  //       newChatContacts,
  //       oldChatContactsId = state.chatContacts.map(j => j.uid)
  //     if (state.chats[action.id]) {
  //       let oldState = state.chats[action.id].msg
  //       sendMsg = [...oldState, action.msg]
  //     } else {
  //       sendMsg = {
  //         ...state.chats,
  //         [action.id]: {
  //           isPinned: action.isPinned,
  //           msg: [action.msg]
  //         }
  //       }
  //     }
  //     if (!oldChatContactsId.includes(action.id)) {
  //       let extractContactInfo = state.contacts.find(k => k.uid === action.id)
  //       newChatContacts = state.chatContacts.concat(extractContactInfo)
  //     } else {
  //       newChatContacts = state.chatContacts
  //     }
  //     return { ...state, chats: sendMsg, chatContacts: newChatContacts }
  //   case "CHANGE_STATUS":
  //     return { ...state, status: action.status }
  //   case "MARK_AS_SEEN":
  //     let marked = state.chats[action.id]
  //     marked !== undefined &&
  //       marked.msg.forEach(msg => {
  //         msg.isSeen = true
  //       })
  //     return { ...state }
  //   case "SEARCH_CONTACTS":
  //     if (action.query.length) {
  //       let filteredContacts = state.contacts.filter(contact => {
  //         return contact.displayName
  //           .toLowerCase()
  //           .includes(action.query.toLowerCase())
  //       })
  //       let filteredChats = state.chatContacts.filter(chat => {
  //         return chat.displayName
  //           .toLowerCase()
  //           .includes(action.query.toLowerCase())
  //       })
  //       return { ...state, filteredContacts, filteredChats }
  //     } else {
  //       return { ...state }
  //     }
  //   case "SET_PINNED":
  //     let pinned = state.chats[action.id]
  //     if (pinned) {
  //       pinned.isPinned = action.value
  //       state.chatContacts.sort((a, b) => b.uid - a.uid)
  //       return { ...state }
  //     } else {
  //       return { ...state }
  //     }
  //   default:
  //     return { ...state }
  // }
  return { ...state }
}

export default chats
