import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import number from "./number/"
import chatReducer from "./chat/"
import numberActiveReducers from "./numberActive/"
import vinculeNumberReducers from "./vinculeNumber/"
import navbar from "./navbar/Index"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  chatApp: chatReducer,
  number: number,
  numberActive: numberActiveReducers,
  vinculeNumber: vinculeNumberReducers
})

export default rootReducer
