// export default {
//     AUTH_URL: 'https://auth.brzomessages.com',
//     ADMIN_URL: 'https://admin.brzomessages.com', //'http://api.brzomessages.com',// 'https://admin.brzomessages.com', //'https://admin.brzomessages.com', //,//
//     BOT_URL: 'http://api.brzomessages.com',
//     SEARCH_URL: 'http://localhost:3337',
//     BILLING_URL: 'http://localhost:3338',
//     PAYPAL_URL: 'https://gateway.brzomessages.com'
// }

export default {
  AUTH_URL: 'https://auth.brzomessages.com',
  ADMIN_URL: 'https://admin.brzomessages.com', //'http://api.brzomessages.com',// 'https://admin.brzomessages.com', //'https://admin.brzomessages.com', //,//
  BOT_URL: 'https://api.brzomessages.com',
  SEARCH_URL: 'https://search.brzomessages.com',
  BILLING_URL: 'https://billing.brzomessages.com',
  PAYPAL_URL: 'https://gateway.brzomessages.com',
  MANAGER_URL: 'https://manager.brzomessages.com'
}
