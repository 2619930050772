import axios from 'axios'

const userKey = '_brzomessages_user'
const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem(userKey)),
  userRole: "admin"
}

if (INITIAL_STATE.user) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${INITIAL_STATE.user.access_token}`
}

export const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    case "LOGOUT": {
      localStorage.removeItem(userKey)
      return { ...state, user: null }
    }
    case "MESSAGE.PAYED.SUCCESS": {
      const { user } = state
      user.balance = action.body.Data.Balance
      user.requestId = action.body.Data.RequestID
      localStorage.setItem(userKey, JSON.stringify(user))
      return { ...state, user: { ...user } }
    }
    case "MESSAGE.REFILL.SUCCESS": {
      const { user } = state
      user.balance = action.body.Data.balance.toFixed(2)
      localStorage.setItem(userKey, JSON.stringify(user))
      return { ...state, user: { ...user } }
    }
    case "MESSAGE.SENDED": {
      if (action.body.Data.Info.Status == 1) {
        const { user } = state
        user.balance -= 0.07
        user.balance = user.balance.toFixed(2)
        localStorage.setItem(userKey, JSON.stringify(user))
        return { ...state, user: { ...user } }
      }
      return { ...state }
    }
    case "LOGIN": {
      if (action.user.access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${action.user.access_token}`
        localStorage.setItem(userKey, JSON.stringify(action.user))
        return { ...state, user: action.user }
      } else {
        return { ...state }
      }
    }
    default: {
      return state
    }
  }
}
